import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const subscribeUserToEmail = async ({ coverKey, patientId, subscribe, source }) => {
  try {
    const API_URL = process.env.REACT_APP_UNSUBS_URL;
    const response = await axios.post(`${API_URL}/EmailSubscribe?secretCode=ditac-1`, {
      coverKey: parseInt(coverKey, 10), 
      patientId,
      subscribe: subscribe === 'true', 
      source,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-helix-apiauth-key': process.env.REACT_APP_HELIX_API_AUTH_KEY || '',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error during unsubscribe:', error);
    throw error; 
  }
};